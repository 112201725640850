import React from "react";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/browser";
import { ApolloQueryResult } from "apollo-client";

import {
  Text,
  Flex,
  Box,
  Anchor,
  UrbanSharingLogo,
  List,
  Li,
  Alert
} from "components";
import { globalData } from "../src/queries";
import {
  SSO_ENDPOINT,
  CORE_ENDPOINT,
  BASE_URL,
  VIEW_AS_QUERY_PARAM,
  PRIVACY_POLICY_URL
} from "../src/constants";
import redirect from "../src/utils/redirect";

import { PublicCustomNextPage } from "../src/types";
import {
  globalData as globalDataQuery,
  globalDataVariables
} from "../src/core-types";
import { getQueryString } from "../src/utils/getQueryString";

type Props = { errors?: ApolloQueryResult<{}>["errors"] };

const LandingPage: PublicCustomNextPage<Props | undefined> = ({ errors }) => {
  const router = useRouter();
  const redirect = BASE_URL + (getQueryString(router.query.redirect) || "");
  const ssoBaseUrl = `${SSO_ENDPOINT || CORE_ENDPOINT}/auth/login`;
  const googleUrl = `${ssoBaseUrl}/google?to=${redirect}`;
  const microsoftUrl = `${ssoBaseUrl}/microsoft?to=${redirect}`;

  return (
    <Flex
      column
      minHeight="100vh"
      height="auto"
      alignItems="center"
      justifyContent="center"
      p="sm"
    >
      <Box>
        <UrbanSharingLogo />
      </Box>
      <Box width={[1, 1, 1 / 2]} mt="md">
        <Text typoStyle="sm" textAlign="center">
          Sign in to make your shared micromobility operations more efficient
        </Text>
      </Box>
      <Box mt="md">
        <a href={googleUrl}>
          <img
            height="60"
            src="/static/google-signin.png"
            alt="Sign in with Google"
            style={{ cursor: "pointer" }}
          />
        </a>

        <a href={microsoftUrl}>
          <img
            height="60"
            src="/static/microsoft-signin.svg"
            alt="Sign in with Microsoft"
            style={{ borderRadius: 8, padding: 4, cursor: "pointer" }}
          />
        </a>
      </Box>
      {errors && (
        <Box>
          <Alert variant="error" mt="xs">
            {errors.map(error => error.message)}
          </Alert>
        </Box>
      )}
      <Box width={[1, 1, 1 / 2, 1 / 2, 1 / 3]} mt="md">
        <Text typoStyle="xxs" textAlign="left">
          <p>
            The Urban Sharing admin console gives partners access to manage all
            aspects of their micromobility and shared assets fleets. Only
            partners have access to the admin console.{" "}
          </p>
          <p>
            We log you in using the account you have with your company&apos;s
            managed Identity Provider. In order to do this, we&apos;ll need
            access to your:{" "}
          </p>
          <Box ml="sm">
            <List listStyle="bullet">
              <Li>Name</Li>
              <Li>Email address associated with your account</Li>
              <Li>Language preference</Li>
            </List>
          </Box>
          <p>
            The first time you login, your Identity Provider&apos;s login
            procedure will ask you to share this with us. We only use this
            information to manage your access to the console. See our{" "}
            <Anchor
              target="_blank"
              rel="noopener noreferrer"
              href={PRIVACY_POLICY_URL}
            >
              privacy policy
            </Anchor>{" "}
            for more information.
          </p>
        </Text>
      </Box>
      <Box mt="sm">
        <Anchor
          target="_blank"
          rel="noopener noreferrer"
          href={PRIVACY_POLICY_URL}
          mr="sm"
        >
          Privacy policy
        </Anchor>
        <Anchor
          target="_blank"
          rel="noopener noreferrer"
          href="https://urbansharing.com"
        >
          urbansharing.com
        </Anchor>
      </Box>
    </Flex>
  );
};

LandingPage.getInitialProps = async ({
  req,
  res,
  apolloClient,
  user,
  query
}) => {
  if (user) {
    const { data, errors } = await apolloClient.query<
      globalDataQuery,
      globalDataVariables
    >({
      query: globalData,
      variables: {
        administratorId:
          (query && getQueryString(query[VIEW_AS_QUERY_PARAM])) || user.id
      }
    });
    if (data) {
      let url = `/${data.administrator.selectedSystem.id}`;
      if (req && req.url && req.url.includes(VIEW_AS_QUERY_PARAM)) {
        url += "?" + VIEW_AS_QUERY_PARAM + "=" + user.id;
      }
      redirect(url, res);
    }
    if (errors) {
      Sentry.captureException(errors);
      return { errors };
    }
  }
  return {};
};

export default LandingPage;
